<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <!-- <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input> -->
      </el-form-item>
      <el-form-item>
        <!-- <el-button @click="getDataList()">查询</el-button> -->
        <el-button v-if="isAuth('wxs:wxscustomer:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button v-if="isAuth('wxs:wxscustomer:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
     <!-- <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="">
      </el-table-column> -->
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="名称">
      </el-table-column>
      <el-table-column
        prop="account"
        header-align="center"
        align="center"
        label="账号">
      </el-table-column>
      <el-table-column
        prop="password"
        header-align="center"
        align="center"
        label="密码">
      </el-table-column>
      <el-table-column
        prop="lastloginTime"
        header-align="center"
        align="center"
        label="最后登录时间">
      </el-table-column>
      <el-table-column
        prop="updateNum"
        header-align="center"
        align="center"
        label="定制升级次数">
      </el-table-column>
      <el-table-column
        prop="firmwareNum"
        header-align="center"
        align="center"
        label="授权固件数">
      </el-table-column>
      <el-table-column
        prop="logFileNum"
        header-align="center"
        align="center"
        label="日志数">
        <template slot-scope="scope">
          <el-button type="text" @click="showLog(scope.row)">{{scope.row.logFilePaths.length}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <el-dialog title="日志文件" :visible.sync="showLogModel">
      <div class="logs" v-if="selectCustomer">
        <el-table :data="selectCustomer.logFilePaths" border style="width: 100%;">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="时间">
            <template slot-scope="scope">
              <a :href="$filePath+`logs/${selectCustomer.account}/${scope.row}`" target="_blank">{{scope.row}}</a>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="150"
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="delLog(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="item" v-for="item in selectCustomer.logFilePaths" :key="item">
          <a :href="$filePath+`logs/${selectCustomer.account}/${item}`" target="_blank">{{item}}</a>
        </div> -->
      </div>
      <el-button type="primary" style="margin: 20px 0 10px 0;" @click="clear">清空</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import AddOrUpdate from './wxscustomer-add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        showLogModel: false,
        selectCustomer: null
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      this.getDataList()
    },
    methods: {
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/wxs/wxscustomer/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'key': this.dataForm.key
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxs/wxscustomer/delete'),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      showLog(c){
        this.selectCustomer = c
        this.showLogModel = true
      },
      delLog(logFile){
        this.$confirm(`确定删除？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxs/wxscustomer/deleteLog'),
            method: 'post',
            data: this.$http.adornData({
              account: this.selectCustomer.account,
              logFileName: logFile
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.selectCustomer.logFilePaths.splice(this.selectCustomer.logFilePaths.indexOf(logFile),1)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
        
      },
      clear(){
        this.$confirm(`确定清空？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxs/wxscustomer/deleteAllLog'),
            method: 'post',
            data: this.$http.adornData({
              account: this.selectCustomer.account
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.selectCustomer.logFilePaths = []
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
